import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import AgentItem, { RenderMode } from "@components/AgentItem";
import SectionIntro from "@components/SectionIntro";
import { useState } from "preact/hooks";
import MicroModal from "micromodal";
function AgentsSummary(props) {
    const ERROR_MESSAGE_NO_AGENTS_TEXT = "There are no agents available";
    const { agents, sectionIntro, onSelectAgent } = props;
    const renderAgentItem = (agent) => {
        const BUTTON_TEXT = "Free Valuation";
        const BUTTON_DISABLED_TEXT = "Selected";
        const [btnText, setBtnText] = useState(BUTTON_TEXT);
        const [modalDuration, setModalDuration] = useState(10000);
        const showConfirmationModal = () => {
            MicroModal.show('modal-agent-selected');
            setTimeout(() => {
                MicroModal.close('modal-agent-selected');
                // Reduce modal duration to 3 seconds
                setModalDuration(3000);
            }, modalDuration);
        };
        const handleOnClick = (e, agent) => {
            e.preventDefault();
            const btn = e.currentTarget;
            showConfirmationModal();
            // Disable button to prevent multiple clicks
            setBtnText(BUTTON_DISABLED_TEXT);
            btn.disabled = true;
            onSelectAgent(agent);
        };
        return (_jsx(AgentItem, { agent: agent, renderMode: RenderMode.Summary, children: _jsx("button", { type: "button", className: "heading-4 btn btn--secondary", onClick: (e) => handleOnClick(e, agent), style: { minWidth: "100%", width: "220px" }, children: btnText }) }));
    };
    if (!agents) {
        // Return message for no agents
        return _jsx("div", { className: "form__group", children: ERROR_MESSAGE_NO_AGENTS_TEXT });
    }
    return (_jsxs("div", { className: "form__group form__group--full-width", children: [_jsx(SectionIntro, { innerHtml: sectionIntro }), _jsx("div", { className: "agents agents-summary", children: _jsx("div", { className: "grid", children: agents.map((agent) => (_jsx("div", { className: "col-12", children: renderAgentItem(agent) }))) }) })] }));
}
export default AgentsSummary;
